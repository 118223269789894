#waline {
  --waline-theme-color: theme('colors.secondary.400');
  --waline-active-color: theme('colors.secondary.500');
}

/* Dark mode styles */
.dark #waline {
  --waline-theme-color: theme('colors.primary.500');
  --waline-active-color: theme('colors.primary.600');
}

#waline .wl-comment {
  @apply gap-x-4;
}
#waline .wl-panel {
  margin: 0;
}
#waline .wl-editor {
  width: calc(100% - 2.4rem);
}

.wl-meta {
  display: none !important;
}
