.p-progressbar .p-progressbar-value {
  background: theme('colors.secondary.300');
}
.dark .p-progressbar .p-progressbar-value {
  background: theme('colors.primary.500');
}

.p-fileupload-buttonbar {
  justify-content: space-around;
}

.progress-spinner {
  position: fixed;
  z-index: 999;
  height: 4em;
  width: 4em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
}

.p-progress-spinner-circle {
  stroke: #f66323 !important;
  stroke-width: 0.2rem;
}

.dark .p-progress-spinner-circle {
  stroke: #21eab0 !important;
  stroke-width: 0.2rem;
}
